/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../../core/JSONSupport.js";
import { property as r } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as e } from "../../../core/accessorSupport/decorators/subclass.js";
let s = class extends o {
  constructor(t) {
    super(t), this.altText = null, this.caption = "", this.title = "", this.type = null;
  }
};
t([r({
  type: String,
  json: {
    write: !0
  }
})], s.prototype, "altText", void 0), t([r({
  type: String,
  json: {
    write: !0
  }
})], s.prototype, "caption", void 0), t([r({
  type: String,
  json: {
    write: !0
  }
})], s.prototype, "title", void 0), t([r({
  type: ["image", "bar-chart", "column-chart", "line-chart", "pie-chart"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], s.prototype, "type", void 0), s = t([e("esri.popup.content.mixins.MediaInfo")], s);
const p = s;
export { p as default };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import t from "./content/AttachmentsContent.js";
import n from "./content/Content.js";
export { default as CustomContent } from "./content/CustomContent.js";
import o from "./content/ExpressionContent.js";
import e from "./content/FieldsContent.js";
import r from "./content/MediaContent.js";
import s from "./content/RelationshipContent.js";
import m from "./content/TextContent.js";
function i(t) {
  return t instanceof n;
}
const p = {
  base: null,
  key: "type",
  typeMap: {
    attachment: t,
    media: r,
    text: m,
    expression: o,
    field: e,
    relationship: s
  }
};
export { t as AttachmentsContent, n as BaseContent, o as ExpressionContent, e as FieldsContent, r as MediaContent, s as RelationshipContent, m as TextContent, i as isContent, p as persistableTypes };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { JSONMap as o } from "../core/jsonMap.js";
import { JSONSupport as e } from "../core/JSONSupport.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as r } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import p from "./support/FieldInfoFormat.js";
var a;
let l = a = class extends e {
  constructor(t) {
    super(t), this.fieldName = null, this.format = void 0, this.isEditable = !0, this.label = null, this.stringFieldOption = "text-box", this.statisticType = null, this.tooltip = null, this.visible = !0;
  }
  clone() {
    return new a({
      fieldName: this.fieldName,
      format: this.format?.clone(),
      isEditable: this.isEditable,
      label: this.label,
      stringFieldOption: this.stringFieldOption,
      statisticType: this.statisticType,
      tooltip: this.tooltip,
      visible: this.visible
    });
  }
};
t([i({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "fieldName", void 0), t([i({
  type: p,
  json: {
    write: !0
  }
})], l.prototype, "format", void 0), t([i({
  type: Boolean,
  json: {
    write: {
      alwaysWriteDefaults: !0
    },
    default: !0
  }
})], l.prototype, "isEditable", void 0), t([i({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "label", void 0), t([r(new o({
  richtext: "rich-text",
  textarea: "text-area",
  textbox: "text-box"
}), {
  default: "text-box"
})], l.prototype, "stringFieldOption", void 0), t([i({
  type: ["count", "sum", "min", "max", "avg", "stddev", "var"],
  json: {
    write: !0
  }
})], l.prototype, "statisticType", void 0), t([i({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "tooltip", void 0), t([i({
  type: Boolean,
  json: {
    write: !0
  }
})], l.prototype, "visible", void 0), l = a = t([s("esri.popup.FieldInfo")], l);
const n = l;
export { n as default };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { clone as o } from "../../core/lang.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { subclass as r } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as s } from "../../core/accessorSupport/decorators/writer.js";
import i from "../FieldInfo.js";
import p from "./Content.js";
var n;
let l = n = class extends p {
  constructor(t) {
    super(t), this.attributes = null, this.description = null, this.fieldInfos = null, this.title = null, this.type = "fields";
  }
  writeFieldInfos(t, o) {
    o.fieldInfos = t && t.map(t => t.toJSON());
  }
  clone() {
    return new n(o({
      attributes: this.attributes,
      description: this.description,
      fieldInfos: this.fieldInfos,
      title: this.title
    }));
  }
};
t([e({
  type: Object,
  json: {
    write: !0
  }
})], l.prototype, "attributes", void 0), t([e({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "description", void 0), t([e({
  type: [i]
})], l.prototype, "fieldInfos", void 0), t([s("fieldInfos")], l.prototype, "writeFieldInfos", null), t([e({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "title", void 0), t([e({
  type: ["fields"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], l.prototype, "type", void 0), l = n = t([r("esri.popup.content.FieldsContent")], l);
const c = l;
export { c as default };
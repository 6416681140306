/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import { JSONSupport as r } from "../../core/JSONSupport.js";
import { property as t } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
let e = class extends r {
  constructor(o) {
    super(o), this.type = null;
  }
};
o([t({
  type: ["attachments", "custom", "fields", "media", "text", "expression", "relationship"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], e.prototype, "type", void 0), e = o([s("esri.popup.content.Content")], e);
const p = e;
export { p as default };
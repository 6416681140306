/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as o } from "../../core/accessorSupport/decorators/subclass.js";
import r from "./Content.js";
var s;
let p = s = class extends r {
  constructor(t) {
    super(t), this.description = null, this.displayType = "auto", this.title = null, this.type = "attachments";
  }
  clone() {
    return new s({
      description: this.description,
      displayType: this.displayType,
      title: this.title
    });
  }
};
t([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "description", void 0), t([e({
  type: ["auto", "preview", "list"],
  json: {
    write: !0
  }
})], p.prototype, "displayType", void 0), t([e({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "title", void 0), t([e({
  type: ["attachments"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], p.prototype, "type", void 0), p = s = t([o("esri.popup.content.AttachmentsContent")], p);
const i = p;
export { i as default };
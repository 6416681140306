/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { property as t } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as r } from "../../core/accessorSupport/decorators/subclass.js";
import o from "./mixins/MediaInfo.js";
import s from "./support/ImageMediaInfoValue.js";
var i;
let a = i = class extends o {
  constructor(e) {
    super(e), this.refreshInterval = 0, this.type = "image", this.value = void 0;
  }
  clone() {
    return new i({
      altText: this.altText,
      title: this.title,
      caption: this.caption,
      refreshInterval: this.refreshInterval,
      value: this.value?.clone()
    });
  }
};
e([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "refreshInterval", void 0), e([t({
  type: ["image"],
  readOnly: !0,
  json: {
    read: !1,
    write: !0
  }
})], a.prototype, "type", void 0), e([t({
  type: s,
  json: {
    write: !0
  }
})], a.prototype, "value", void 0), a = i = e([r("esri.popup.content.ImageMediaInfo")], a);
const p = a;
export { p as default };
/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import { JSONSupport as r } from "../../../core/JSONSupport.js";
import { property as s } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { subclass as t } from "../../../core/accessorSupport/decorators/subclass.js";
var e;
let p = e = class extends r {
  constructor(o) {
    super(o), this.linkURL = null, this.sourceURL = null;
  }
  clone() {
    return new e({
      linkURL: this.linkURL,
      sourceURL: this.sourceURL
    });
  }
};
o([s({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "linkURL", void 0), o([s({
  type: String,
  json: {
    write: !0
  }
})], p.prototype, "sourceURL", void 0), p = e = o([t("esri.popup.content.support.ImageMediaInfoValue")], p);
const c = p;
export { c as default };
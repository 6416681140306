/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { strict as r } from "../../../core/jsonMap.js";
const c = r()({
  barchart: "bar-chart",
  columnchart: "column-chart",
  linechart: "line-chart",
  piechart: "pie-chart"
});
export { c as chartTypeKebabDict };
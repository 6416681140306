/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import { clone as e } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
import d from "./support/RelatedRecordsInfoFieldOrder.js";
var p;
let l = p = class extends r {
  constructor(o) {
    super(o), this.showRelatedRecords = null, this.orderByFields = null;
  }
  clone() {
    return new p({
      showRelatedRecords: this.showRelatedRecords,
      orderByFields: this.orderByFields ? e(this.orderByFields) : null
    });
  }
};
o([s({
  type: Boolean,
  json: {
    write: !0
  }
})], l.prototype, "showRelatedRecords", void 0), o([s({
  type: [d],
  json: {
    write: !0
  }
})], l.prototype, "orderByFields", void 0), l = p = o([t("esri.popup.RelatedRecordsInfo")], l);
const c = l;
export { c as default };